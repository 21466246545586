import React from 'react';
import './Step.css';

export default function Step({ image, heading, subheading }) {
  return (
    <div className="step">
      <div className="step-image">
        <img src={image} alt={heading} />
      </div>
      <div className="step-details">
        <h3>{heading}</h3>
        <p>{subheading}</p>
      </div>
    </div>
  );
}
