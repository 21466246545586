import React, { Suspense, useState, useEffect } from 'react';
import Routes from '../routes';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './App.css';

function App() {
  const [direction, setDirection] = useState('');

  useEffect(() => {
    AOS.init({
      duration: 900,
    });

    let locale = window.localStorage.getItem('i18nextLng');

    if (locale.includes('ar')) {
      window.localStorage.setItem('i18nextLng', 'ar');
      setDirection('rtl');
    } else {
      window.localStorage.setItem('i18nextLng', 'en');
      setDirection('ltr');
    }
  }, []);

  function setup(dir) {
    document.body.dir = dir;
    document.body.style.textAlign = 'initial';
  }

  return (
    <Suspense fallback="loading">
      {setup(direction)}
      <div className=".App">
        <Routes />
      </div>
    </Suspense>
  );
}

export default App;
