import React from 'react';
import Layout from '../components/Layout';
import { useTranslation } from 'react-i18next';

import './CareerInfo.css';

const CareerInfo = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="container">
        <div className="row d-flex align-items-center mobile-textcenter">
          <div className="col-md-6">
            <h1 className="main-heading">{t('career-info.heading')}</h1>
            <div className="d-flex mobile-center">
              <p className="mr-5 text-muted text-subheading fix-margin">
                <img
                  src="/assets/location-icon.svg"
                  alt="location"
                  className="mx-2"
                />
                {t('career-info.location')}
              </p>
              <p className="text-muted text-subheading">
                <img
                  src="/assets/design-icon.svg"
                  alt="design"
                  className="mx-2"
                />
                {t('career-info.team')}
              </p>
            </div>
          </div>

          <div className="col-2"></div>
          <div className="col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-apply"
              onClick={() => (window.location.href = `mailto:hr@noqod.com.sd`)}
            >
              <img
                src="/assets/applynow-icon.svg"
                alt="apply"
                className="mx-1"
              />
              {t('career-info.btn')}
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <p className="my-5 text-justify text-content">
              {t('career-info.para1')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <span className="list-heading">
              {t('career-info.list1.heading')}
            </span>
            <ul className=" p-3 ml-5 text-content">
              <li>{t('career-info.list1.item1')}</li>
              <li>{t('career-info.list1.item2')}</li>
              <li>{t('career-info.list1.item3')}</li>
              <li>{t('career-info.list1.item4')}</li>
              <li>{t('career-info.list1.item5')}</li>
              <li>{t('career-info.list1.item6')}</li>
              <li>{t('career-info.list1.item7')}</li>
              <li>{t('career-info.list1.item8')}</li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-md-10">
            <span className="list-heading">
              {t('career-info.list2.heading')}
            </span>
            <ul className="p-3 ml-5 text-content">
              <li>{t('career-info.list2.item1')}</li>
              <li>{t('career-info.list2.item2')}</li>
              <li>{t('career-info.list2.item3')}</li>
              <li>{t('career-info.list2.item4')}</li>
              <li>{t('career-info.list2.item5')}</li>
              <li>{t('career-info.list2.item6')}</li>
              <li>{t('career-info.list2.item7')}</li>
              <li>{t('career-info.list2.item8')}</li>
            </ul>
          </div>
        </div>

        <div className="row mt-5 align-items-center text-center">
          <div className="col-md-6">
            <h3>{t('career-info.sub-heading')}</h3>
          </div>

          <div className="col-2"></div>

          <div className="col-md-3">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-apply "
              onClick={() => (window.location.href = `mailto:hr@noqod.com.sd`)}
            >
              <img
                src="/assets/applynow-icon.svg"
                alt="apply"
                className="mx-1"
              />
              {t('career-info.btn')}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CareerInfo;
