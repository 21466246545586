import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import './Careers.css';

const Careers = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('careers.title')}</title>
      </Helmet>
      <Layout>
        <div className="container main-container">
          <div className="row">
            <h1 className="mx-auto heading-text">{t('careers.heading')}</h1>
          </div>

          <div className="row">
            <p className="mx-auto my-3">{t('careers.sub-heading')}</p>
          </div>

          <div className="row mt-4">
            <p className="mx-auto">
              <b>{t('careers.openings')}</b> {t('careers.across')}{' '}
              <b>{t('careers.loc')}</b>
            </p>
          </div>

          <div className="row my-3">
            <div className="col-md-12 card card-shadow">
              <div className="row table-responive-md">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{t('careers.table.col1')}</th>
                      <th scope="col">{t('careers.table.col2')}</th>
                      <th scope="col">{t('careers.table.col3')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Link className="career-position" to="/career-info">
                          {t('careers.positions.prod-designer.position')}
                        </Link>
                      </td>
                      <td>{t('careers.positions.prod-designer.team')}</td>
                      <td>{t('careers.positions.prod-designer.location')}</td>
                    </tr>
                    {/* <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Frontend Developer
                      </Link>
                    </td>
                    <td>Engineering</td>
                    <td>India</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Manager
                      </Link>
                    </td>
                    <td>Business Development</td>
                    <td>Sudan</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Public Relations Execute
                      </Link>
                    </td>
                    <td>Support</td>
                    <td>India</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Product Designer
                      </Link>
                    </td>
                    <td>Design</td>
                    <td>Sudan</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Backend Developer
                      </Link>
                    </td>
                    <td>Engineering</td>
                    <td>India</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        Backend Developer
                      </Link>
                    </td>
                    <td>Engineering</td>
                    <td>India</td>
                  </tr>
                  <tr>
                    <td>
                      <Link className="career-position" to="#">
                        System Engineer
                      </Link>
                    </td>
                    <td>Engineering</td>
                    <td>India</td>
                  </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Careers;
