import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/utils/ScrollToTop';
import TC from './Views/Terms';
import PrivacyPolicy from './Views/PrivacyPolicy';
import Home from './Views/Home';
import NoqodPayments from './Views/NoqodPayments';
import NoqodBusiness from './Views/NoqodBusiness';
import ContactUs from './Views/ContactUs';
import Sitemap from './Views/Sitemap';
import Careers from './Views/Careers';
import CareerInfo from './Views/CareerInfo';
import AboutUs from './Views/AboutUs';
import Blog from './Views/Blog';
import Newsroom from './Views/Newsroom';
import Support from './Views/Support';
import Branding from './Views/Branding';
import FourOhFour from './Views/FourOhFour';
import PaymentGateway from './Views/PaymentGateway';

const Routes = () => {
  return (
    <Router onUpdate={() => window.scrollTo(0, 0)}>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/terms-and-Conditions" component={TC} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/noqod-payments" component={NoqodPayments} />
        <Route path="/noqod-business" component={NoqodBusiness} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/sitemap" component={Sitemap} />
        <Route path="/careers" component={Careers} />
        <Route path="/career-info" component={CareerInfo} />
        <Route path="/blog" component={Blog} />
        <Route path="/newsroom" component={Newsroom} />
        <Route path="/support" component={Support} />
        <Route path="/branding" component={Branding} />
        <Route path="/payment-gateway" component={PaymentGateway} />
        <Route path="*" component={FourOhFour} />
      </Switch>
    </Router>
  );
};

export default Routes;
