import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './AboutUs.css';
import Layout from './../components/Layout';
import FeatureSection from '../components/utils/FeatureSection';
import TeamMember from '../components/utils/TeamMember';
import { useTranslation } from 'react-i18next';

export default function AboutUs() {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  return (
    <div>
      <Helmet>
        <title>{t('about-us.title')}</title>
        <meta
          name="description"
          content="NOQOD Makes your lives free of convolutions and hassles of carrying cash in your pockets and make the payments and transfers through the phone."
        />
      </Helmet>
      <Layout className="aboutUs-container">
        <div className="aboutUs-header">
          <h1 className="aboutUs-heading">{t('about-us.heading')}</h1>
          <img
            src={
              locale.includes('en')
                ? '/assets/aboutUs_header.png'
                : '/assets/aboutUs_header--ar.png'
            }
            className="aboutUs-hero"
            alt="about us"
          />
        </div>
        <div className="introduction_section">
          <div className="introduction">
            <img src="/assets/ic_introduction.svg" alt="introduction" />
            <h3 className="aboutUs_headings">{t('about-us.intro')}</h3>
            <p>{t('about-us.intro-text')}</p>
          </div>
        </div>
        <div className="knowUsSection">
          <div className="knowUs">
            <h3 className="aboutUs_headings">{t('about-us.know-us')}</h3>
            <p>{t('about-us.know-us-text')}</p>
          </div>
          <img
            className="knowUs-image"
            src="/assets/image_knowUs.png"
            alt="different aspects of NOQOD"
          />
        </div>
        {/* <FeatureSection
          heading="The brains behind your empowerment"
          color="#000000"
        >
          <div className="noqodTeam">
            <TeamMember
              avatar="/assets/avatar_iqra.png"
              name="Iqra Nazir"
              designation="QA & Content Manager"
              bio="When Iqra is not ensuring the quality of our software projects in the team; she loves to express the art of writing such that people have to open dictionary. For developers in our team; she is a trouble maker whereas to our audience, she presents it well on the table just like she loves to serve her cooked meals with aroma."
              linkedin="https://www.linkedin.com"
              twitter="https://www.twitter.com"
              facebook="https://www.facebook.com"
            />
            <TeamMember
              avatar="/assets/avatar_iqra.png"
              name="Iqra Nazir"
              designation="QA & Content Manager"
              bio="When Iqra is not ensuring the quality of our software projects in the team; she loves to express the art of writing such that people have to open dictionary. For developers in our team; she is a trouble maker whereas to our audience, she presents it well on the table just like she loves to serve her cooked meals with aroma."
              linkedin="https://www.linkedin.com"
              twitter="https://www.twitter.com"
              facebook="https://www.facebook.com"
            />
          </div>
        </FeatureSection> */}
        <div className="joinTheTeam">
          <h2>{t('about-us.heading2')}</h2>
          <Link to="/careers">
            {t('about-us.btn')}
            <img
              src={
                locale.includes('en')
                  ? '/assets/ic_viewAll.svg'
                  : '/assets/ic_viewAll--ar.svg'
              }
              alt="View All"
            />
          </Link>
        </div>
      </Layout>
    </div>
  );
}
