import React from 'react';
import { Helmet } from 'react-helmet';

import './NoqodBusiness.css';
import Layout from './../components/Layout';
import Button from './../components/utils/Button';
import FeatureSection from './../components/utils/FeatureSection';
import { useTranslation } from 'react-i18next';

export default function NoqodBusiness() {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  return (
    <div>
      <Helmet>
        <title>{t('noqod-business.title')}</title>
        <meta
          name="description"
          content="Empowering business with the options of QR based payments, facile transactions through the app, bank deposits, invoice generations & more."
        />
      </Helmet>
      <Layout className="noqodBusiness">
        <div className="noqodBusiness-heroSection">
          <h1>
            <span>{t('noqod-business.sub-heading-bold')}</span>
            {t('noqod-business.sub-heading')}
          </h1>
          <div className="noqodBusiness-buttons">
            <Button
              text={t('noqod-business.btn1')}
              primary={true}
              color="green"
              url="https://play.google.com/store/apps/details?id=com.noqodbusiness&hl=en_IN&gl=US"
              outerLink={true}
            />
            <Button
              text={t('noqod-payments.btn2')}
              url="https://apps.apple.com/in/app/noqod-business/id1576472439"
              primary={true}
              color="green"
              outerLink={true}
            />
          </div>
          <img
            class="heroImage"
            src={
              locale.includes('en')
                ? './assets/image_merchantPhones.png'
                : './assets/image_merchantPhones--ar.png'
            }
            alt=""
          />
        </div>
        <FeatureSection
          heading={t('noqod-business.why-noqod-business.heading')}
          color="#000000"
        >
          <div className="noqodBusiness-grid">
            <div className="nb-grid-image" data-aos="fade-right">
              <img
                src={
                  locale.includes('en')
                    ? '/assets/image_qrPayments.png'
                    : '/assets/image_qrPayments--ar.png'
                }
              />
            </div>
            <div className="nb-grid-details" data-aos="fade-left">
              <h3>{t('noqod-business.why-noqod-business.qr.heading')}</h3>
              <p>{t('noqod-business.why-noqod-business.qr.sub-heading')}</p>
            </div>
          </div>
          <div className="noqodBusiness-grid">
            <div className="nb-grid-image" data-aos="fade-left">
              <img
                src={
                  locale.includes('en')
                    ? '/assets/image_nfcPayments.png'
                    : '/assets/image_nfcPayments--ar.png'
                }
              />
            </div>
            <div className="nb-grid-details" data-aos="fade-right">
              <h3>{t('noqod-business.why-noqod-business.nfc.heading')}</h3>
              <p>{t('noqod-business.why-noqod-business.nfc.sub-heading')}</p>
            </div>
          </div>
          <div className="noqodBusiness-grid">
            <div className="nb-grid-image" data-aos="fade-right">
              <img src="/assets/image_offers.png" />
            </div>
            <div className="nb-grid-details" data-aos="fade-left">
              <h3>{t('noqod-business.why-noqod-business.offers.heading')}</h3>
              <p>{t('noqod-business.why-noqod-business.offers.sub-heading')}</p>
            </div>
          </div>
        </FeatureSection>
      </Layout>
      <div class="layers">
        <div class="layer one"></div>
        <div class="layer two"></div>
        <div class="layer three"></div>
      </div>
    </div>
  );
}
