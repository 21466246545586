import React, { useState } from 'react';
import Layout from './../components/Layout';
import Address from './../components/utils/Address';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

import './ContactUs.css';

const ContactUs = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isVerified, setIsVerified] = useState(false);

  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();
    const sendData = { ...formData, 'g-recaptcha-response': recaptchaValue };
    if (isVerified) {
      axios
        .post('https://backoffice.noqod.sd/web/v1/contactUs', sendData)
        .then((response) => {
          console.log('RESPONSE', response.data);
          alert('Thanks for contacting us...');
          clearForm();
        })
        .catch((err) => {
          console.log('SOMETHING WENT WRONG...', err);
        });
    } else {
      alert('pls verify that you are a human');
    }
    clearForm();
    recaptchaRef.current.reset();
  };

  const clearForm = () => {
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
    });
    // recaptchaRef.current.reset();
  };

  function onChange(value) {
    setIsVerified(true);
    // console.log('Captcha value:', value);
  }

  return (
    <Layout>
      <div className="container">
        <div className="row main-container">
          <div className="col-md-7 ">
            <div className="card p-5">
              <h1 className="mb-3 heading-text">
                {t('contact-us.form.heading')}
              </h1>

              <form method="post" className="mt-5" onSubmit={handleSubmit}>
                <div className="form-group row mb-4 d-flex  align-items-center">
                  <div className="col-md-3">
                    <label for="name" className="form-label">
                      {t('contact-us.form.name')}
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control form-input"
                      id="name"
                      name="name"
                      placeholder={t('contact-us.form.placeholder.name')}
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row mb-4 d-flex  align-items-center">
                  <div className="col-md-3">
                    <label for="email" className="form-label">
                      {t('contact-us.form.email')}
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control form-input"
                      id="email"
                      name="email"
                      placeholder={t('contact-us.form.placeholder.email')}
                      value={formData.email}
                      onChange={handleChange}
                      required
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    />
                  </div>
                </div>

                <div className="form-group row mb-4 d-flex align-items-center">
                  <div className="col-md-3">
                    <label for="subject" className="form-label">
                      {t('contact-us.form.subject')}
                    </label>
                  </div>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control form-input"
                      id="subject"
                      name="subject"
                      placeholder={t('contact-us.form.placeholder.subject')}
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <div className="col-md-3">
                    <label for="msg" className="form-label">
                      {t('contact-us.form.message')}
                    </label>
                  </div>
                  <div className="col-md-9">
                    <textarea
                      type="text"
                      className="form-control form-input form-input-msg"
                      id="message"
                      name="message"
                      placeholder={t('contact-us.form.placeholder.message')}
                      value={formData.message}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                {/* -----------------------------RECAPTCHA------------------ */}

                <div className="d-flex mb-3 captcha">
                  <ReCAPTCHA
                    style={{}}
                    ref={recaptchaRef}
                    // sitekey="6LftC-QZAAAAAMs-CDm-ZOJaufwGBi08Z6Ue798R"
                    sitekey="6Lcb7OMZAAAAAD0Zy_agh_Auy9TuveMlMu-cP9SC"
                    onChange={onChange}
                  />
                </div>
                {/* -----------------------------RECAPTCHA END------------------ */}

                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg btn-submit"
                  >
                    {t('contact-us.form.btn')}
                    <img
                      src="/assets/submit-icon.svg"
                      alt="submit"
                      className="mx-1 flip-arrow"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-5">
            <div className="mt-5">
              <img
                src="/assets/image_contactUs.svg"
                alt="contact"
                className="contact-img"
              />
            </div>
          </div>
        </div>

        {/* ----------------------------Address------------------------------ */}

        <div className="row mt-5 pt-5">
          <div className="col-md-7">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="NOQOD headquaters"
                  width="100%"
                  height="535"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=bahri%2C%20sudan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://www.whatismyip-address.com/divi-discount/"></a>
              </div>
            </div>
          </div>

          <div className="col-md-5 p-5 address-container">
            <div className="my-3">
              <h4 className="contact-address">
                {t('contact-us.address.addr.label')}
              </h4>
              <Address
                addressHeader={t('contact-us.address.headquarters')}
                address={t('contact-us.address.addr.text')}
                directionURL="https://www.google.com/maps/place/Khartoum+North,+Sudan/@15.656266,32.546596,13z/data=!4m5!3m4!1s0x168e9284e16db7d7:0xf617dea9fa16e7e1!8m2!3d15.6569409!4d32.5485873?hl=en-US"
              />
              <Address
                addressHeader={t('contact-us.address.headOffice')}
                address="Unit GA-00-SZ-L1-RT-201 Level 1, Gate Avenue - South Zone, Dubai International Financial Centre"
                directionURL="https://www.google.com/maps?q=Gate+Avenue+-+South+Zone,+Dubai+International+Financial+Centre&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjU08Kut57xAhVZWH0KHSfjBsQQ_AUoAXoECAEQAw"
              />
              <Address
                addressHeader={t('contact-us.address.research')}
                address="STPI - Rangreth, Srinagar, 190019"
                directionURL="https://www.google.com/maps/place/Software+Technology+Parks+of+India+-+Rangreth/@34.0016883,74.7935836,43m/data=!3m1!1e3!4m12!1m6!3m5!1s0x38e18f98619be59d:0x199f97a720c506fa!2sSoftware+Technology+Parks+of+India+-+Rangreth!8m2!3d34.0017347!4d74.793599!3m4!1s0x38e18f98619be59d:0x199f97a720c506fa!8m2!3d34.0017347!4d74.793599"
              />
            </div>

            {/* <div className="d-flex align-items-center mb-4">
              <h4 className="contact-address">GET DIRECTION</h4>
              <img
                src="/assets/contact-Arrow.svg"
                alt="arrow"
                className="mb-1 ml-2"
              />
            </div> */}

            <div className="mb-4">
              <h4 className="contact-address">
                {t('contact-us.address.email.label')}
              </h4>
              <p>{t('contact-us.address.email.text')}</p>
            </div>

            <div>
              <h4 className="contact-address">
                {t('contact-us.address.phone.label')}
              </h4>
              <p style={{ marginBottom: '0px' }}>
                {t('contact-us.address.phone.text')}
              </p>
              <small>{t('contact-us.address.days')}</small>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUs;
