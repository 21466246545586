import React from 'react';

import Header from './header/Header';
import Footer from './footer/Footer';

export default function Layout({ children }) {
  return (
    <div>
      <Header />
      <main
        className="children"
        style={{ margin: '100px auto', padding: '30px', maxWidth: '1080px' }}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}
