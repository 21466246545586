import React from 'react';

const Divider = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-12"
          style={{
            height: '1px',
            backgroundColor: '#bcbcbc',
            marginTop: '80px',
          }}
        ></div>
      </div>
    </div>
  );
};

export default Divider;
