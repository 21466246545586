import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Header.css';
import NoqodContour from './NoqodContour';
import NoqodUpshots from './NoqodUpshots';
import LangSwitcher from './LangSwitcher';
import LinkCard from './../utils/LinkCard';

function Header() {
  const { t } = useTranslation();

  const productsRef = useRef();
  const aboutRef = useRef();

  const [isShown, setIsShown] = useState(false);
  const [left, setLeft] = useState(0);
  const [display, setDisplay] = useState('hidden');
  const [toShow, setToShow] = useState(null);
  const [mobileMenu, setMobileMenu] = useState('hidden');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let leftValue =
      (productsRef.current.getBoundingClientRect().left +
        productsRef.current.getBoundingClientRect().left) /
      2;
    setLeft(leftValue);
  }, []);

  const handleProductsMouseEnter = () => {
    let leftValue =
      (productsRef.current.getBoundingClientRect().left +
        productsRef.current.getBoundingClientRect().left) /
      2;

    setIsShown((prevState) => !prevState);
    setLeft(leftValue);
    setDisplay('visible');
    setToShow('products');
  };

  const handleAboutMouseEnter = () => {
    let leftValue =
      (aboutRef.current.getBoundingClientRect().left +
        aboutRef.current.getBoundingClientRect().left) /
      2;

    setIsShown((prevState) => !prevState);
    setLeft(leftValue);
    setDisplay('visible');
    setToShow('about');
  };

  const handleNavEnter = (e) => {
    e.stopPropagation();
    setDisplay('visible');
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    setDisplay('hidden');
  };

  const handleNav = (e) => {
    if (!e.target.className.includes('subMenuLink')) {
      setDisplay('hidden');
    }
  };

  return (
    <div className="navBar" onMouseMove={handleNav}>
      <header className="header">
        <nav>
          <div className="logoContainer">
            <div className="logo">
              <Link to="/">
                <img src="/assets/noqod-logo.svg" alt="" />
              </Link>
            </div>
            <div
              className="mobileMenuToggle"
              onClick={(e) => {
                setMobileMenu('visible');
                setIsOpen(true);
              }}
            >
              <img src="/assets/ic_menu.svg" alt="menu" />
            </div>
          </div>
          <div className="links">
            <ul className="linkList">
              <li>
                <Link className="navLink" to="/">
                  {t('header.home')}
                </Link>
              </li>
              <li
                onMouseEnter={handleAboutMouseEnter}
                onFocus={handleAboutMouseEnter}
              >
                <Link
                  className="navLink subMenuLink about"
                  to="#"
                  ref={aboutRef}
                >
                  {t('header.about')}
                </Link>
              </li>
              <li
                onMouseEnter={handleProductsMouseEnter}
                onFocus={handleProductsMouseEnter}
              >
                <Link
                  className="navLink subMenuLink products"
                  to="#"
                  ref={productsRef}
                >
                  {t('header.products&services')}
                </Link>
              </li>
              <li>
                <Link className="navLink" to="/contact-us">
                  {t('header.contact')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="language">
            <LangSwitcher />
          </div>
        </nav>
      </header>
      <div
        style={{
          visibility: display,
          position: 'absolute',
          top: `70px`,
          left: `${left}px`,
          zIndex: '50',
        }}
        className="subMenuCards"
        onMouseMove={handleNavEnter}
        onMouseLeave={handleMouseLeave}
      >
        {toShow === 'products' ? <NoqodUpshots /> : <NoqodContour />}
      </div>
      <div className="mobileNav-container" style={{ visibility: mobileMenu }}>
        <div className="mobile-nav">
          <div className="mobileNav-header">
            <div className="logo">
              <Link to="/">
                <img src="/assets/noqod-logo.png" alt="" />
              </Link>
            </div>
            <div
              className="mobileMenuToggle"
              onClick={(e) => setMobileMenu('hidden')}
            >
              <img src="/assets/ic_cross.svg" alt="close" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-around">
            <div>{t('header.selectLang')}</div>
            <LangSwitcher hide={setMobileMenu} open={isOpen} />
          </div>
          <NoqodUpshots />
          <NoqodContour />
          <div className="contact-contour">
            <LinkCard
              image="/assets/ic_contactUs.svg"
              text={t('header.contact')}
              url="/contact-us"
              border
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
