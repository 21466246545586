import React from 'react';

import './NoqodUpshots.css';
import DetailedLinkCard from './../utils/DetailedLinkCard';

import { useTranslation } from 'react-i18next';

export default function NoqodUpshots() {
  const { t } = useTranslation();
  return (
    <div className="noqodUpshots">
      <div className="subLinks_header">
        <p>{t('header.upshots.heading')}</p>
      </div>
      <div className="subLink_noqodUpshots">
        <DetailedLinkCard
          url="/noqod-payments"
          image="/assets/ic_noqodPayments.svg"
          heading={t('header.upshots.payments')}
          subHeading={t('header.upshots.payments-subheading')}
        />
        <DetailedLinkCard
          url="/noqod-business"
          image="/assets/ic_noqodBusiness.svg"
          heading={t('header.upshots.business')}
          subHeading={t('header.upshots.business-subheading')}
        />
        <DetailedLinkCard
          url="/payment-gateway"
          image="/assets/ic_npg.svg"
          heading={t('header.upshots.gateway')}
          subHeading={t('header.upshots.gateway-subheading')}
        />
        {/* <DetailedLinkCard
          url="#"
          image="/assets/ic_noqodEmpty.svg"
          heading="NOQOD Payments"
          subHeading="Mobile application that consummates all the payment solutions"
        />
        <DetailedLinkCard
          url="#"
          image="/assets/ic_noqodEmpty.svg"
          heading="NOQOD Payments"
          subHeading="Mobile application that consummates all the payment solutions"
        /> */}
      </div>
    </div>
  );
}
