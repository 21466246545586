import React from 'react';
import { Link } from 'react-router-dom';

const ImageButton = ({ url, image, alt }) => {
  return (
    <div>
      <a href={url} target="_blank">
        <img src={image} alt={alt}></img>
      </a>
    </div>
  );
};

export default ImageButton;
