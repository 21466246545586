import React from 'react';
import { Link } from 'react-router-dom';

import './footerLinks.css';

const footerList = ({ header, links }) => {
  return (
    <div>
      <h3 className="linksHeader">{header}</h3>
      <div className="linksContainer">
        {links.map((link) => {
          return (
            <Link className="listLink" to={link.url}>
              {link.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default footerList;
