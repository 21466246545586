import React from 'react';
import { useTranslation } from 'react-i18next';
import './Address.css';

export default function Address({ addressHeader, address, directionURL }) {
  const { t } = useTranslation();

  return (
    <div>
      <div className="addressHeader">
        <p className="addressHeading">{addressHeader}</p>
        <p>
          <a href={directionURL} target="_blank">
            {t('contact-us.address.getDirection')}
          </a>
        </p>
      </div>
      <p>{address}</p>
    </div>
  );
}
