import React from 'react';
import Helmet from 'react-helmet';

import './Branding.css';
import Layout from './../components/Layout';
import ColorPalette from './../components/utils/ColorPalette';
import { useTranslation } from 'react-i18next';

export default function Branding() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('branding.title')}</title>
      </Helmet>
      <Layout>
        <div className="branding-header">
          <h1>{t('branding.heading')}</h1>
          <p>
            {t('branding.sub-heading')}
            <a href="#" download>
              {t('branding.link')}
            </a>
            .
          </p>
        </div>
        <div className="our-logo">
          <h3>{t('branding.our-logo')}</h3>
          <p>{t('branding.logo-text')}</p>
        </div>
        <div className="logo-full">
          <div className="logo">
            <img src="/assets/noqod_logo--color.svg" alt="noqod" />
          </div>
          <div className="download-links">
            <a href="/assets/noqod_logo--color.svg" download>
              .svg
            </a>
            <a href="/assets/noqod_logo--color.png" download>
              .png
            </a>
          </div>
        </div>
        <div className="logo-full white-logo">
          <div className="logo">
            <img src="/assets/noqod_logo--white.svg" alt="noqod" />
          </div>
          <div className="download-links">
            <a href="/assets/noqod_logo--white.svg" download>
              .svg
            </a>
            <a href="/assets/noqod_logo--white.png" download>
              .png
            </a>
          </div>
        </div>
        {/* <div className="our-logo">
          <h3>ALTERNATIVE LOGO AND BADGES</h3>
          <p>
            Please do not edit, change, distort, recolor, or reconfigure the
            Art.
          </p>
        </div> */}
        <div className="our-logo">
          <h3>{t('branding.colors.heading')}</h3>
        </div>
        <div className="colors">
          <ColorPalette
            colorName={t('branding.colors.blue')}
            hex="#388FE9"
            rgb="(56, 143, 233)"
            cmyk="(76, 39, 0, 9)"
          />
          <ColorPalette
            colorName={t('branding.colors.green')}
            hex="#46CE7C"
            rgb="(70, 206, 124)"
            cmyk="(76, 39, 0, 9)"
          />
          <ColorPalette
            colorName={t('branding.colors.black')}
            hex="#333333"
            rgb="(51, 51, 51)"
            cmyk="(0, 0, 0, 80)"
          />
          <ColorPalette
            colorName={t('branding.colors.grey')}
            hex="#BDBDBD"
            rgb="(189, 189, 189)"
            cmyk="(0, 0, 0, 26)"
          />
        </div>
      </Layout>
    </div>
  );
}
