import React from 'react';
import './FeatureCard.css';

const FeatureCard = ({ image, altText, heading, body }) => {
  return (
    <div className="featureCard">
      <div className="image">
        <img src={image} alt={altText} />
      </div>
      <div className="details">
        <h4>{heading}</h4>
        <p>{body}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
