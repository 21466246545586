import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from './../components/Layout';
import { useTranslation } from 'react-i18next';
import Button from './../components/utils/Button';
import FeatureSection from '../components/utils/FeatureSection';
import FeatureCard from '../components/utils/FeatureCard';
import IntegrationStep from './../components/utils/IntegrationStep';

import './PaymentGateway.css';

const PaymentGateway = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t('gateway.title')}</title>
        <meta
          name="description"
          content="Give your business an exponential growth with NOQOD Payment gateway"
        />
      </Helmet>
      <Layout>
        <div className="container">
          <div className="row">
            <h1 className="text-center heading-text mx-auto">
              {t('gateway.heading')}
              <span className="heading-color">
                {t('gateway.heading-color')}
              </span>
            </h1>
          </div>

          <div className="row">
            <p className="text-center mx-auto my-3">
              {t('gateway.sub-heading')}
            </p>

            <div className="gateway-buttons">
              <Button
                primary={true}
                text={t('gateway.btn1')}
                color="purple"
                url="https://npg.noqod.com.sd/login"
                outerLink={true}
              />
              <Button
                primary={true}
                outerLink={true}
                color="purple"
                text={t('gateway.btn2')}
                url="https://github.com/NOQOD/payment-gateway-library/"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <img
                className="img-fluid"
                src="./assets/gatewayMain.png"
                alt="Hero image"
              />
            </div>
          </div>

          <div className="row mt-5 pt-5">
            <h1 className="mx-auto text-center font-weight-bold">
              {t('gateway.feature-section.heading')} <br />
              <span className="heading-color">
                {t('gateway.feature-section.heading-color')}
              </span>
            </h1>
          </div>

          <div className="row feature-container">
            <FeatureSection>
              <div className="noqodPayments-services-grid" data-aos="fade-up">
                <FeatureCard
                  image="/assets/feature1.svg"
                  altText="Online payments"
                  heading={t('gateway.feature-section.feature1.heading')}
                  body={t('gateway.feature-section.feature1.sub-heading')}
                />
                <FeatureCard
                  image="/assets/feature2.svg"
                  altText="empowering"
                  heading={t('gateway.feature-section.feature2.heading')}
                  body={t('gateway.feature-section.feature2.sub-heading')}
                />
                <FeatureCard
                  image="/assets/feature3.svg"
                  altText="Bill Payments"
                  heading={t('gateway.feature-section.feature3.heading')}
                  body={t('gateway.feature-section.feature3.sub-heading')}
                />
                <FeatureCard
                  image="/assets/feature4.svg"
                  altText="Bank deposits."
                  heading={t('gateway.feature-section.feature4.heading')}
                  body={t('gateway.feature-section.feature4.sub-heading')}
                />
              </div>
            </FeatureSection>
          </div>

          <div className="row mx-lg-n5">
            <div className="col-md-6" data-aos="fade-down">
              <h1 className="heading-text">
                {t('gateway.integration.heading')}
                <span className="heading-color">
                  {t('gateway.integration.heading-color')}
                </span>
              </h1>

              <div className="my-5">
                <IntegrationStep
                  text={t('gateway.integration.steps.zero')}
                  img="./assets/integration_step1.svg"
                />
                <IntegrationStep
                  text={t('gateway.integration.steps.one')}
                  img="./assets/integration_step1.svg"
                />
                <IntegrationStep
                  text={t('gateway.integration.steps.two')}
                  img="./assets/integration_step1.svg"
                />
                <IntegrationStep
                  text={t('gateway.integration.steps.three')}
                  img="./assets/integration_step1.svg"
                />
                <IntegrationStep
                  text={t('gateway.integration.steps.four')}
                  img="./assets/integration_step.svg"
                />
              </div>
              <div className="mt-5" className="documentation_btn">
                <Button
                  style={{ padding: 0 }}
                  primary={true}
                  outerLink={true}
                  color="purple"
                  text={t('gateway.btn2')}
                  url="https://github.com/NOQOD/payment-gateway-library/"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="code_image">
                <img
                  className="img-fluid"
                  src="./assets/integration_code.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <div className="gateway-layer"></div>
      <div className="gateway-layer-bottom"></div>
    </div>
  );
};

export default PaymentGateway;
