import React from 'react';
import { Link } from 'react-router-dom';

import './DetailedLinkCard.css';

export default function LinkCard({ image, url, heading, border, subHeading }) {
  return (
    <div>
      <Link className="DetailedLinkCard" to={url}>
        <div>
          <img src={image} alt={heading} />
        </div>
        <div>
          <p className="heading">{heading}</p>
          <p className="subheading">{subHeading}</p>
        </div>
      </Link>
    </div>
  );
}
