import React from 'react';

export default function EmptyScreen({ image, text }) {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <img
        src={image}
        alt="page is empty"
        style={{ marginBottom: '30px', maxWidth: '100%' }}
      />
      <p style={{ fontSize: '30px', fontWeight: '700' }}>{text}</p>
    </div>
  );
}
