import React from 'react';
import { useTranslation } from 'react-i18next';
import './LangSwitcher.css';

function LanguageSwitcher({ hide, open }) {
  const { i18n } = useTranslation();

  return (
    <div className="select">
      <select
        className="custom-select lang-switch"
        value={i18n.language}
        onChange={(e) => {
          i18n.changeLanguage(e.target.value);
          if (open) {
            hide('hidden');
          }
          document.body.dir = i18n.dir();
          window.localStorage.getItem('rtl') === 'true' || ''
            ? localStorage.setItem('rtl', false)
            : localStorage.setItem('rtl', true);
        }}
      >
        <option value="en">English</option>
        <option value="ar">العربية</option>
      </select>
    </div>
  );
}

export default LanguageSwitcher;
