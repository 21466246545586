import React from 'react';
import Helmet from 'react-helmet';

import './policy.css';
import Layout from './../components/Layout';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('privacy.title')}</title>
      </Helmet>
      <Layout>
        <div className="privacyPolicy policy">
          <h1>{t('privacy.heading')}</h1>
          <p>{t('privacy.para1')}</p>
          <h3>{t('privacy.sub-heading1')}</h3>
          <p>{t('privacy.para2')}</p>
          <a
            href="https://www.google.com/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('privacy.link1')}
          </a>
          <a
            href="https://firebase.google.com/support/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('privacy.link2')}
          </a>
          <h3>{t('privacy.sub-heading2')}</h3>
          <p>{t('privacy.para3')}</p>
          <h3>{t('privacy.sub-heading3')}</h3>
          <p>{t('privacy.para4')}</p>
          <h3>{t('privacy.sub-heading4')}</h3>
          <p>
            {t('privacy.para5.line1')}
            <br /> {t('privacy.para5.line2')}
            <br /> {t('privacy.para5.line3')}
            <br /> {t('privacy.para5.line4')}
            <br />
            {t('privacy.para5.line5')}
          </p>
          <h3>{t('privacy.sub-heading5')}</h3>
          <p>{t('privacy.para6')}</p>
          <h3>{t('privacy.sub-heading6')}</h3>
          <p>{t('privacy.para7')}</p>
          <h3>{t('privacy.sub-heading7')}</h3>
          <p>{t('privacy.para8')}</p>
          <h3>{t('privacy.sub-heading8')}</h3>
          <p>
            {t('privacy.para9')}
            <strong>{t('privacy.para9-bold')}</strong>
          </p>
          <h3>{t('privacy.sub-heading9')}</h3>
          <p>
            {t('privacy.para10')}
            <a href="mailto:info@noqod.com.sd">info@noqod.com.sd.</a>
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default PrivacyPolicy;
