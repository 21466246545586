import React from 'react';
import { Link } from 'react-router-dom';

import './Button.css';

export default function Button({
  primary,
  color = null,
  url,
  text,
  outerLink,
  disabled,
}) {
  let buttonColor = !primary
    ? '#FFFFFF00'
    : color === 'blue'
    ? '#388FE9'
    : color === 'purple'
    ? '#7002b9'
    : '#46CE7C';

  let renderButton = () => {
    if (outerLink) {
      return (
        <a className="buttonLink" href={url} target="_blank">
          {text}
        </a>
      );
    } else {
      return (
        <Link className="buttonLink" to={url}>
          {text}
        </Link>
      );
    }
  };

  return (
    <div
      className={primary ? `button_primary` : `button`}
      style={{
        backgroundColor: buttonColor,
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      {renderButton()}
    </div>
  );
}
