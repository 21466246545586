import React from 'react';

import './ColorPalette.css';

export default function ColorPalette({ colorName, hex, rgb, cmyk }) {
  return (
    <div className="color-palette" style={{ backgroundColor: hex }}>
      <h4>{colorName}</h4>
      <p>
        HEX: <strong>{hex}</strong>
      </p>
      <p>
        RGB: <strong>{rgb}</strong>
      </p>
      <p>
        CMYK: <strong>{cmyk}</strong>
      </p>
    </div>
  );
}
