import React from 'react';
import FooterLinks from './FooterLinks';
import ImageButton from './../utils/ImageButton';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './footer.css';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footerBackground">
      <div className="footerContainer">
        <div className="footerLeft">
          <div className="footerLogo">
            <Link to="/">
              <img src={'/assets/noqod-logo.svg'} alt="NOQOD Logo" />
            </Link>
          </div>
          <div className="footerText">
            <p>{t('footer.tagline')}</p>
          </div>
          <div className="socialLinks">
            <ImageButton
              image="/assets/ic_facebook.svg"
              altText="NOQOD's facebook"
              url="https://www.facebook.com/noqod/"
            />
            <ImageButton
              image="/assets/ic_twitter.svg"
              altText="NOQOD's Twitter"
              url="https://twitter.com/Noqod1?s=09"
            />
            <ImageButton
              image="/assets/ic_instagram.svg"
              altText="NOQOD's Instagram"
              url="https://instagram.com/noqod_pay?igshid=14jhybvt6ftqi"
            />
            <ImageButton
              image="/assets/ic_linkedin.svg"
              altText="NOQOD's Linkedin"
              url="https://www.linkedin.com/company/noqod"
            />
          </div>
        </div>
        <div className="footerLinkSection">
          <FooterLinks
            header={t('footer.products.heading')}
            links={[
              { title: t('footer.products.link1'), url: '/noqod-payments' },
              { title: t('footer.products.link2'), url: '/noqod-business' },
              { title: t('footer.products.link3'), url: '/payment-gateway' },
            ]}
          />
          <FooterLinks
            header={t('footer.company.heading')}
            links={[
              { title: t('footer.company.link1'), url: '/about-us' },
              // { title: 'Know us', url: '#' },
              // { title: 'Why NOQOD', url: '#' },
              // { title: 'Leadership', url: '#' },
              // { title: 'Blog', url: '/blog' },
              // { title: 'Newsletter', url: '/newsroom' },
              { title: t('footer.company.link2'), url: '/careers' },
            ]}
          />
          <FooterLinks
            header={t('footer.resources.heading')}
            links={[
              // { title: 'Support', url: '#' },
              { title: t('footer.resources.link1'), url: '/contact-us' },
              {
                title: t('footer.resources.link2'),
                url: '/terms-and-conditions',
              },
              { title: t('footer.resources.link3'), url: '/privacy-policy' },
              // { title: 'Sitemap', url: '/sitemap' },
              { title: t('footer.resources.link4'), url: '/branding' },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <p>
          {t('footer.copyrights')}
          <strong>{t('footer.copyrights-bold')}</strong>{' '}
          {t('footer.copyrights-end')}
        </p>
      </div>
    </div>
  );
};

export default Footer;
