import React from 'react';

import Layout from './../components/Layout';
import EmptyScreen from './../components/utils/EmptyScreen';

export default function Newsroom() {
  return (
    <div>
      <Layout>
        <EmptyScreen image="/assets/ic_emptyScreen.svg" text="No news yet" />
      </Layout>
    </div>
  );
}
