import React from 'react';
import './FeatureShowcase.css';

export default function FeatureShowcase({
  image,
  heading,
  subheading,
  money,
  shadowDirection,
}) {
  let shadow =
    shadowDirection === 'right'
      ? '20px 21px 25px rgba(111, 104, 104, 0.25)'
      : '-20px 21px 25px rgba(111, 104, 104, 0.25)';

  return (
    <div className="featureShowcase" style={{ boxShadow: shadow }}>
      <div className="featureShowcase_details">
        <div>
          <img src={image} alt={heading} />
        </div>
        <div className="featureShowcase_Text">
          <p className="featureShowcase_heading">{heading}</p>
          <p className="featureShowcase_subHeading">{subheading}</p>
        </div>
      </div>
      <div className="puppet"></div>
      <div className="featureShowcase_money">
        <p>{money}</p>
      </div>
    </div>
  );
}
