import React from 'react';
import { Helmet } from 'react-helmet';

import './NoqodPayments.css';
import Layout from '../components/Layout';
import Button from '../components/utils/Button';
import FeatureSection from '../components/utils/FeatureSection';
import FeatureCard from '../components/utils/FeatureCard';
import Step from '../components/utils/Step';
import { useTranslation } from 'react-i18next';

export default function NoqodPayments() {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  return (
    <div>
      <Helmet>
        <title>{t('noqod-payments.title')}</title>
        <meta
          name="description"
          content="NOQOD Makes your lives free of convolutions and hassles of carrying cash in your pockets and make the payments and transfers through the phone."
        />
      </Helmet>
      <Layout>
        <div className="noqodPayments-hero">
          <h1 className="noqodPayments-heroText">
            {t('noqod-payments.heading')}
          </h1>
          <p>{t('noqod-payments.sub-heading')}</p>
          <div className="noqodPayments-buttons">
            <Button
              text={t('noqod-payments.btn1')}
              primary={true}
              color="blue"
              outerLink={true}
              url="https://play.google.com/store/apps/details?id=com.noqod"
            />
            <Button
              text={t('noqod-payments.btn2')}
              url="https://apps.apple.com/in/app/noqod/id1569598468"
              primary={true}
              color="blue"
              outerLink={true}
            />
          </div>
        </div>
        <div className="noqodPayments-services">
          <FeatureSection
            heading={t('noqod-payments.services.heading')}
            color="#000000"
          >
            <div className="noqodPayments-services-grid" data-aos="fade-up">
              <FeatureCard
                image="/assets/ic_onlinePayments.svg"
                altText="Online payments"
                heading={t('noqod-payments.services.div1.heading')}
                body={t('noqod-payments.services.div1.sub-heading')}
              />
              <FeatureCard
                image="/assets/ic_moneyTransfer.svg"
                altText="empowering"
                heading={t('noqod-payments.services.div2.heading')}
                body={t('noqod-payments.services.div2.sub-heading')}
              />
              <FeatureCard
                image="/assets/ic_billPayments.svg"
                altText="Bill Payments"
                heading={t('noqod-payments.services.div3.heading')}
                body={t('noqod-payments.services.div3.sub-heading')}
              />
              <FeatureCard
                image="/assets/ic_bankDeposits.svg"
                altText="Bank deposits."
                heading={t('noqod-payments.services.div4.heading')}
                body={t('noqod-payments.services.div4.sub-heading')}
              />
            </div>
          </FeatureSection>
        </div>
        <div className="noqodPayments-download">
          <FeatureSection
            heading={t('noqod-payments.how-noqod-works.heading')}
            color="#000000"
          >
            <div className="noqodPayments-download-grid" data-aos="fade-right">
              <div className="noqodPayments-download-image">
                <img
                  src={
                    locale.includes('en')
                      ? '/assets/image_noqodWorks.png'
                      : '/assets/image_noqodWorks--ar.png'
                  }
                />
              </div>
              <div
                className="noqodPayments-download-detail"
                data-aos="fade-left"
              >
                <Step
                  image="/assets/ic_donwloadApp.svg"
                  heading={t('noqod-payments.how-noqod-works.step1-heading')}
                  subheading={t('noqod-payments.how-noqod-works.step1-text')}
                />
                <Step
                  image="/assets/ic_registerApp.svg"
                  heading={t('noqod-payments.how-noqod-works.step2-heading')}
                  subheading={t('noqod-payments.how-noqod-works.step2-text')}
                />
                <Step
                  image="/assets/ic_attach.svg"
                  heading={t('noqod-payments.how-noqod-works.step3-heading')}
                  subheading={t('noqod-payments.how-noqod-works.step3-text')}
                />
                <Step
                  image="/assets/ic_enjoy.svg"
                  heading={t('noqod-payments.how-noqod-works.step4-heading')}
                  subheading={t('noqod-payments.how-noqod-works.step4-text')}
                />
              </div>
            </div>
          </FeatureSection>
        </div>
        <div className="tapToPay">
          <div className="tapToPay-details" data-aos="fade-right">
            <h3>
              <span className="blueText">
                {t('noqod-payments.how-noqod-works.taptopay')}
              </span>
              <br />
              {t('noqod-payments.how-noqod-works.taptopay-text')}
            </h3>
            <div className="tapToPay-downloadButtons">
              <div className="tapToPay-downloadButton">
                <a
                  href="https://play.google.com/store/apps/details?id=com.noqod"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/assets/ic_googlePlay.svg"
                    alt="Download android app"
                  />
                </a>
              </div>
              <div className="tapToPay-downloadButton">
                <a
                  href="https://apps.apple.com/in/app/noqod/id1569598468"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="/assets/ic_appStore.svg" alt="Download IOS App" />
                </a>
              </div>
            </div>
          </div>
          <div className="tapToPay-image" data-aos="fade-left">
            <img
              src={
                locale.includes('en')
                  ? '/assets/image_tapToPay.png'
                  : '/assets/image_tapToPay--ar.png'
              }
            ></img>
          </div>
        </div>
        <img
          className="noqodPayments-heroImage"
          src={
            locale.includes('en')
              ? '/assets/noqodPayments-heroImage.png'
              : '/assets/noqodPayments-heroImage--ar.png'
          }
        />
        <img
          className="noqodPayments-heroImage-800"
          src={
            locale.includes('en')
              ? '/assets/noqodPayments-800.png'
              : '/assets/noqodPayments-800--ar.png'
          }
        />
      </Layout>
      <div class="paymentsLayer"></div>
    </div>
  );
}
