import React from 'react';

import './Integration.css';

const IntegrationStep = ({ text, img }) => {
  return (
    <div className="steps-container mt-2">
      <div className="steps-container__step">
        <img src={img} alt="step" />
      </div>
      <p style={{ fontSize: '18px' }}>{text}</p>
    </div>
  );
};

export default IntegrationStep;
