import React from 'react';

import LinkCard from './../utils/LinkCard';
import './NoqodContour.css';

import { useTranslation } from 'react-i18next';

export default function NoqodContour() {
  const { t } = useTranslation();
  return (
    <div className="subLinks">
      <div className="subLinks_header">
        <p>{t('header.contour')}</p>
      </div>
      <div className="subLink">
        <LinkCard
          image="/assets/ic_aboutus.svg"
          text={t('header.contour-about')}
          url="/about-us"
          border
        />
        {/* <LinkCard image="/assets/ic_knowus.svg" text="Know us" url="#" border />
        <LinkCard
          image="/assets/ic_whyNoqod.svg"
          text="Why NOQOD"
          url="#"
          border
        />
        <LinkCard
          image="/assets/ic_meetTeam.svg"
          text="Leadership"
          url="#"
          border
        /> */}
        {/* <LinkCard image="/assets/ic_blog.svg" text="Blog" url="/blog" border />
        <LinkCard
          image="/assets/ic_newsroom.svg"
          text="Newsroom"
          url="/newsroom"
          border
        /> */}
        <LinkCard
          image="/assets/ic_career.svg"
          text={t('header.contour-career')}
          url="/careers"
          border
        />
      </div>
    </div>
  );
}
