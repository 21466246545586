import React from 'react';
import { Link } from 'react-router-dom';

import './LinkCard.css';

export default function LinkCard({ image, url, text, border }) {
  return (
    <div>
      <Link className="LinkCard" to={url}>
        <img src={image} alt={text} />
        <p>{text}</p>
      </Link>
    </div>
  );
}
