import React from 'react';
import Layout from './../components/Layout';
import Divider from '../components/utils/Divider';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Sitemap.css';

const Sitemap = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Layout>
        <div className="container mobile-textcenter">
          <h1>{t('sitemap.heading')}</h1>

          <Divider />

          <div className="row">
            <div className="col-md-3">
              <h3 className="my-3">{t('sitemap.about.heading')}</h3>
            </div>
            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link1')}
                </Link>
              </div>

              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link2')}
                </Link>
              </div>

              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link3')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link4')}
                </Link>
              </div>

              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link5')}
                </Link>
              </div>

              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link6')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link7')}
                </Link>
              </div>

              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link8')}
                </Link>
              </div>

              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.about.link9')}
                </Link>
              </div>
            </div>
          </div>

          <Divider />

          <div className="row">
            <div className="col-md-3">
              <h3 className="my-3">{t('sitemap.products.heading')}</h3>
            </div>
            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.products.link1')}
                </Link>
              </div>

              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.products.link2')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.products.link3')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.products.link4')}
                </Link>
              </div>
            </div>
          </div>

          <Divider />

          <div className="row">
            <div className="col-md-3">
              <h3 className="my-3">{t('sitemap.legal.heading')}</h3>
            </div>
            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link1')}
                </Link>
              </div>

              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link2')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link3')}
                </Link>
              </div>
              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link4')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link5')}
                </Link>
              </div>
              <div>
                <Link className="sitemap-links" to="#">
                  {t('sitemap.legal.link6')}
                </Link>
              </div>
            </div>
          </div>

          <Divider />

          <div className="row">
            <div className="col-md-3">
              <h3 className="my-3">{t('sitemap.resources.heading')}</h3>
            </div>
            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.resources.link1')}
                </Link>
              </div>
            </div>

            <div className="col-md-3">
              <div className="my-3">
                <Link className="sitemap-links" to="#">
                  {t('sitemap.resources.link2')}
                </Link>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Sitemap;
