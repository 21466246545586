import React from 'react';
import Helmet from 'react-helmet';

import './policy.css';
import Layout from './../components/Layout';
import { useTranslation } from 'react-i18next';

const TC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('terms.title')}</title>
      </Helmet>
      <Layout>
        <div className="privacyPolicy policy">
          <h1>{t('terms.heading')}</h1>
          <p>{t('terms.para1')}</p>
          <p>{t('terms.para2')}</p>
          <p>{t('terms.para3')}</p>
          <p>
            {t('terms.para4')}
            <br />
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('terms.link1')}
            </a>
            <a
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('terms.link2')}
            </a>
          </p>
          <p>{t('terms.para5')}</p>
          <p>{t('terms.para6')}</p>
          <p>{t('terms.para7')}</p>
          <p>{t('terms.para8')}</p>
          <p>{t('terms.para9')}</p>
          <h3>{t('terms.sub-heading')}</h3>
          <p>
            {t('terms.para10')}
            <strong>
              {t('terms.para10-bold')}
            </strong>
          </p>
          <p>
            {t('terms.para11')}
            <a href="mailto:info@noqod.com.sd">info@noqod.com.sd.</a>
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default TC;
