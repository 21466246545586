import React from 'react';
import Layout from '../components/Layout';

function FourOhFour() {
  return (
    <div>
      <Layout>404</Layout>
    </div>
  );
}

export default FourOhFour;
