import React from 'react';
import './FeatureSection.css';

export default function FeatureSection({
  heading,
  subHeading,
  children,
  color,
  fontSize,
}) {
  return (
    <div className="featureSection">
      <h2 style={{ color: color, fontSize: fontSize }}>{heading}</h2>
      <p>{subHeading}</p>
      <div className="children">{children}</div>
    </div>
  );
}
