import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';

import '../components/App.css';
import Layout from './../components/Layout';
import FeatureCard from '../components/utils/FeatureCard';
import FeatureSection from '../components/utils/FeatureSection';
import Button from '../components/utils/Button';
import FeatureShowCase from '../components/utils/FeatureShowcase';

function Home() {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  return (
    <div className="App">
      <Helmet>
        <title>{t('home.title')}</title>
        <meta
          name="description"
          content="Unravelling payment snags to empowering human lives. A journey of easier, simpler and secure payments using your smartphone."
        />
      </Helmet>
      <Layout>
        <div className="hero">
          <div className="heroText">
            <h1>{t('home.heading')}</h1>
            <p>{t('home.sub-heading')}</p>
            <div className="buttons">
              <Button
                // text="See all products"
                text={t('home.btn1')}
                primary={true}
                color="green"
                url="/noqod-payments"
              />

              <Button text={t('home.btn2')} url="/contact-us" />
            </div>
          </div>
          <div className="heroContainer">
            <div className="heroContainerImage">
              <img src="/assets/heroImages.png" />
            </div>
            <div className="heroContainerText">
              <img
                src={
                  locale.includes('en')
                    ? '/assets/heroText.png'
                    : '/assets/heroText--ar.png'
                }
              />
            </div>
          </div>
        </div>
        <FeatureSection
          heading={t('home.whynoqod.heading')}
          subHeading={t('home.whynoqod.sub-heading')}
        >
          <div data-aos="fade-up" className="whyNoqodGrid">
            <FeatureCard
              image="/assets/ic_empower.svg"
              altText="empowering"
              heading={t('home.whynoqod.div1.heading')}
              body={t('home.whynoqod.div1.sub-heading')}
            />
            <FeatureCard
              image="/assets/ic_technicalKnack.svg"
              altText="Technical knack"
              heading={t('home.whynoqod.div2.heading')}
              body={t('home.whynoqod.div2.sub-heading')}
            />
            <FeatureCard
              image="/assets/ic_assuredExperience.svg"
              altText="Assured Experience"
              heading={t('home.whynoqod.div3.heading')}
              body={t('home.whynoqod.div3.sub-heading')}
            />
            <FeatureCard
              image="/assets/ic_empower.svg"
              altText="24*7 help availability"
              heading={t('home.whynoqod.div4.heading')}
              body={t('home.whynoqod.div4.sub-heading')}
            />
          </div>
        </FeatureSection>
        <FeatureSection
          heading={t('home.products.heading')}
          color="#000000"
          fontSize="30"
        >
          <div className="nProducts nPayments">
            <div className="nProducts_details" data-aos="fade-right">
              <h3>{t('home.products.noqod-payments.heading')}</h3>
              <p>{t('home.products.noqod-payments.sub-heading')}</p>
              <div>
                <Link to="/noqod-payments" className="linkButton">
                  <p style={{ color: '#388FE9' }}>
                    {t('home.products.noqod-payments.link')}
                  </p>
                  <img
                    src={
                      locale.includes('en')
                        ? '/assets/ic_goLink-blue.svg'
                        : '/assets/ic_goLink-blue--ar.svg'
                    }
                  />
                </Link>
              </div>
            </div>
            <div className="nProducts_image" data-aos="fade-left">
              <img
                src={
                  locale.includes('en')
                    ? '/assets/image_noqodPayments.png'
                    : '/assets/image_noqodPayments--ar.png'
                }
                alt="NOQOD Business"
              />
              <div className="nb1">
                <FeatureShowCase
                  image="/assets/ic_np1.png"
                  heading={t(
                    'home.products.noqod-payments.features.feature1.heading'
                  )}
                  subheading={t(
                    'home.products.noqod-payments.features.feature1.sub-heading'
                  )}
                  money="SDG 120"
                  shadowDirection="left"
                />
              </div>
              <div className="np2">
                <FeatureShowCase
                  image="/assets/ic_np2.png"
                  heading={t(
                    'home.products.noqod-payments.features.feature2.heading'
                  )}
                  subheading={t(
                    'home.products.noqod-payments.features.feature2.sub-heading'
                  )}
                  money="SDG 140"
                  shadowDirection="right"
                />
              </div>
            </div>
          </div>
          <div className="nProducts nBusiness">
            <div className="nProducts_image" data-aos="fade-right">
              <img
                src={
                  locale.includes('en')
                    ? '/assets/image_noqodBusiness.png'
                    : '/assets/image_noqodBusiness--ar.png'
                }
                alt="NOQOD Business"
              />
              <div className="nb1">
                <FeatureShowCase
                  image="/assets/ic_nb2.png"
                  heading={t(
                    'home.products.noqod-business.features.feature1.heading'
                  )}
                  subheading={t(
                    'home.products.noqod-business.features.feature1.sub-heading'
                  )}
                  money="SDG 100"
                  shadowDirection="left"
                />
              </div>
              <div className="nb2">
                <FeatureShowCase
                  image="/assets/ic_nb1.png"
                  heading={t(
                    'home.products.noqod-business.features.feature2.heading'
                  )}
                  subheading={t(
                    'home.products.noqod-business.features.feature2.sub-heading'
                  )}
                  money="SDG 500"
                  shadowDirection="right"
                />
              </div>
            </div>
            <div className="nProducts_details" data-aos="fade-left">
              <h3>{t('home.products.noqod-business.heading')}</h3>
              <p>{t('home.products.noqod-business.sub-heading')}</p>
              <div>
                <Link to="/noqod-business" className="linkButton">
                  <p>{t('home.products.noqod-business.link')}</p>
                  <img
                    src={
                      locale.includes('en')
                        ? '/assets/ic_goLink-green.svg'
                        : '/assets/ic_goLink-green--ar.svg'
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </FeatureSection>
      </Layout>
      {/* <img className="heroBackground" src="/assets/heroBackground.png" /> */}
    </div>
  );
}

export default Home;
